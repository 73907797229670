@import "./variables.scss";

.home {
    overflow-x: hidden;

    .spanlove {
        color: $red;
        font-size: 3rem;
    }

    .faded-button {
        button {
            background: rgba(#4e76f4, 0.7) !important;
            font-weight: 500;
            height: max-content;
            padding: 0.65rem;
        }
    }

    .hero {
        height: 500px;
        background: url("../Assets/Header.jpg") no-repeat center center;
        background-size: cover;

        .container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            
            @media (max-width: $md) {
                justify-content: center;
            }

            .overlay_header {
                background: $red;
                width: fit-content;
                color: #ffffff;
                padding: 1rem;
                border-radius: 7px;
                user-select: none;

                h1 {
                    white-space: nowrap;
                }
            }
        }
    }

    .section_1,
    .section_2,
    .section_3,
    .section_4,
    .section_5,
    .section_6 {
        min-height: 230px;
        padding: 3rem 0;
        justify-content: center;

        @media (max-width: $md) {
            padding: 2rem 0;
        }
    }

    .section_2 {
        background: #f4f4f4;

        .flex_images {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .section_3 {
        background: $red;
        color: #f6f6f6;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section_4 {
        background: #f4f4f4;
    }
}
