.mint {
    padding: 2rem 0;

    h1 {
        text-align: center;
    }

    .accordion-header{
        button{
            width: 100%;
        }
    }

    .accordion-button:not(.collapsed) {
        color: #222222;
        font-weight: 600;
        background-color: #ffebeb;
        box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    }

    .accordion-button:focus {
        color: #222222;
        font-weight: 600;
        z-index: 3;
        border-color: #aaaaaa;
        outline: 0;
        box-shadow: #aaaaaa;
    }

    .accordion-button {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
        font-size: 1rem;
        font-weight: 600;
        color: var(--bs-accordion-btn-color);
        text-align: left;
        background-color: var(--bs-accordion-btn-bg);
        border: 0;
        border-radius: 0;
        overflow-anchor: none;
        transition: var(--bs-accordion-transition);
    }

    .close-button {
        border: none;
        display: inline-block;
        padding: 8px 16px;
        vertical-align: middle;
        overflow: hidden;
        text-decoration: none;
        color: inherit;
        background-color: inherit;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
    }

    .topright {
        position: absolute;
        right: 0;
        top: 0;
    }

    .Modal {
        background: #f8f9fa;
        border: 1px solid black;
        border-radius: 10px;
        position: absolute;
        top: 10%;
        left: 5%;
        right: 5%;
        bottom: 20%;
        padding: 2%;
    }

    button {
        width: auto;
    }
    @media only screen and (max-width: 1200px) {
        /*Tablets [601px -> 1200px]*/
        .Modal {
            position: -ms-device-fixed;
            top: 2%;
            left: 2%;
            right: 2%;
            bottom: 1%;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
        .choosecontainer {
            display: flex;
        }
        label {
            display: flex;
        }
        .form-group {
            display: flex;
            width: 100%;
            height: 100%;
            display: block;
            justify-content: center;
        }
        input {
            width: auto;
        }
    }

    * {
        box-sizing: border-box;
        font-family: "Poppins", sans-serif;
    }
    body {
        display: grid;
        min-height: 100vh;
    }

    .form-row {
        display: flex;
        width: 100%;
    }

    .alignment {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
    }

    .choosecontainer {
        display: flex;
        width: 100%;
        height: 100%;
        display: block;
    }
    .imgdiv {
        width: 200px;
        height: 200px;
        border: 0.3rem solid #fff;
        border-radius: 50%;
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.4);
        overflow: hidden;
    }

    .imgselect {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
    }
    .streched {
        display: block;
        width: 100%;
    }
    .form-inline {
        display: flex;
        flex-direction: column;
    }
    .card {
        /* This is a CSS comment!*/
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        border-radius: 0.5rem;
        position: relative;
        cursor: pointer;
        border: 0.5rem solid transparent;
        transition: 200ms ease-in-out transform;
    }
    .check_btn {
        position: absolute;
        top: 8%;
        right: 8%;
        background-color: rgba(92, 92, 92, 0.4);
        width: 2rem;
        height: 2rem;
        display: grid;
        place-items: center;
        border-radius: 0.5rem;
    }
    .fa-check {
        color: #fff;
    }
    .title {
        margin: 1.5rem 0;
    }
    .title,
    ul li {
        color: #1f1208;
        text-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.4);
    }

    .card:hover {
        transform: scale(1.02);
    }
    .card:active {
        transform: scale(0.97);
    }

    input[id="card_one"]:checked ~ label[for="card_one"] .card,
    input[id="card_two"]:checked ~ label[for="card_two"] .card,
    input[id="card_three"]:checked ~ label[for="card_three"] .card {
        border-color: rgb(21, 238, 21);
    }
    /* you can see based on which card is checked the border color will be changed */
    /* ~ selector selects the sibling elements, in this case the label */

    input[id="card_one"]:checked ~ label[for="card_one"] .card .check_btn,
    input[id="card_two"]:checked ~ label[for="card_two"] .card .check_btn,
    input[id="card_three"]:checked ~ label[for="card_three"] .card .check_btn {
        background-color: rgb(21, 238, 21);
    }

    input[id="card_one"]:checked ~ label[for="card_one"] .card .img,
    input[id="card_two"]:checked ~ label[for="card_two"] .card .img,
    input[id="card_three"]:checked ~ label[for="card_three"] .card .img {
        border-color: rgb(21, 238, 21);
        box-shadow: 0 0 0.5rem rgba(21, 238, 21, 0.6);
    }

}
